import { graphql, useStaticQuery } from "gatsby";
import { getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import QuestionForm from "../components/basicForm/QuestionForm";
import Layout from "../components/Layout";
import { PrivacyDisclaimer } from "../components/shared/FormStyles";
import BasicFormTemplate from "../templates/BasicFormTemplate";

const seo = {
  title: "Ask a Question",
  description:
    "Got a question for one of our materials scientists? Get in contact with the Plastometrex team today",
};

const title = `Got a question for our materials scientists?`;
const byline = `Please submit any questions you have for our friendly materials science team and we’ll be in touch as soon as we can. If it’s not a straightforward question we can organise a quick call to discuss, just indicate you’d like a call via the form below.`;

const AskAQuestion = () => {
  const data = useStaticQuery(graphql`
    query {
      render: file(relativePath: { eq: "team_strip.png" }) {
        childImageSharp {
          gatsbyImageData(width: 844, placeholder: NONE)
        }
      }
    }
  `);

  const image = getImage(data.render) as IGatsbyImageData;

  return (
    <Layout seo={seo} backgroundColorUnderneath="white">
      <BasicFormTemplate title={title} byline={byline} image={image}>
        <QuestionForm />
        <PrivacyDisclaimer mobile />
      </BasicFormTemplate>
    </Layout>
  );
};

export default AskAQuestion;
